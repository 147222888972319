import React, { useContext, useMemo } from "react"
import { Layout } from "../../layouts"
import "./Impressum.scss"
import { graphql } from "gatsby"
import SEO from "../../components/Seo"
import { translateText } from "../../utils/translateText"
import { LanguageContext } from "../../contexts/languageContext"
import parse from "html-react-parser"
import { localizeDataNodes } from "../../utils/localizeDataNodes"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"

const ImpressumData = ({ data }) => {
  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const googleSheetData = languageContext[2]

  const { register, represented, according, taxId, legal } = data

  return (
    <>
      <section className="top-container">
        <div className="top-wrapper">
          <h1 className="title">
            {translateText(
              "Imprint",
              currentLanguage.fullTitle,
              googleSheetData
            )}
          </h1>
          <h2>
            {translateText(
              "This website is powered by operated by Dzemo",
              currentLanguage.fullTitle,
              googleSheetData
            )}
          </h2>
          <div className="cards-container">
            <div className="card">
              <h3>
                {translateText(
                  "Register entry",
                  currentLanguage.fullTitle,
                  googleSheetData
                )}
              </h3>
              <p>{parse(register)}</p>
            </div>
            <div className="card">
              <h3>
                {translateText(
                  "Represented by",
                  currentLanguage.fullTitle,
                  googleSheetData
                )}
              </h3>
              <p>{parse(represented)}</p>
            </div>
            <div className="card">
              <h3>
                {translateText(
                  "According to § 5 TMG",
                  currentLanguage.fullTitle,
                  googleSheetData
                )}
              </h3>
              <p>{parse(according)}</p>
            </div>
            <div className="card">
              <h3>
                {translateText(
                  "Tax ID",
                  currentLanguage.fullTitle,
                  googleSheetData
                )}
              </h3>
              <p>{parse(taxId)}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="bottom-container">
        <div className="bottom-wrapper">
          <h2>
            {translateText(
              "Legal Notice",
              currentLanguage.fullTitle,
              googleSheetData
            )}
          </h2>
          <div className="content">{parse(legal)}</div>
        </div>
      </section>
    </>
  )
}

const Impressum = ({ pageContext: { pageData, lang, companies }, data, location }) => {
  const { allDzemoApiCompanies } = useMemo(
    () => localizeDataNodes(data, lang),
    [data, lang]
  )

  return (
    <Layout location={location} lang={lang} pageData={pageData} companies={companies}>
      <SEO
        title={capitalizeFirstLetter(pageData[lang].name)}
        canonical={location.pathname}
        data={pageData[lang]}
      />
      <div className="impressum-page">
        <ImpressumData data={allDzemoApiCompanies[0]} lang={lang} />
      </div>
    </Layout>
  )
}

export default Impressum

export const query = graphql`
  {
    allDzemoApiCompanies(limit: 1) {
      nodes {
        id
        en {
          register
          represented
          according
          taxId
          legal
        }
        de {
          register
          represented
          according
          taxId
          legal
        }
      }
    }
  }
`
